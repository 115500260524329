<template>
  <div class="navbar-header d-xl-block d-none" style="width: 200px;">
    <b-img fluid :src="appLogoImage" alt="logo" />
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage
    }
  }
}
</script>

<style></style>
