export default [
  {
    header: 'lobby',
    icon: 'HomeIcon',
    title: 'หน้าแรก',
    route: 'lobby'
  },
  {
    header: 'Deposit',
    icon: 'DollarSignIcon',
    title: 'ฝาก',
    route: 'deposit'
  },
  {
    header: 'Withdrawal',
    icon: 'SmileIcon',
    title: 'ถอน',
    route: 'withdraw'
  },
  {
    header: 'Promotion',
    icon: 'StarIcon',
    title: 'โปรโมชั่น',
    route: 'promotion'
  },
  {
    header: 'Cashback',
    icon: 'PackageIcon',
    title: 'รับเครดิตเงินคืน',
    route: 'cashback'
  }
  // {
  //   header: 'Setting',
  //   icon: 'SettingsIcon',
  //   title: 'ตั้งค่า',
  //   route: 'setting'
  // }
]
